<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.thailand.header') }}</p>
            <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
            <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.thailand.header') }) }}</p>
          </div>
          <div class="form_main">
            <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="zotaRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, zotaForm.amount)" prop="amount">
                      <numeric-input
                        v-model="zotaForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li class="data">
                    <p>
                      {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'THB' }) }}
                      <span>{{ rate }}</span>
                    </p>
                    <p>
                      THB: <span>{{ getRate(rateChange) }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="zotaForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitXpayForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
            <div v-html="bankHTML"></div>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo circle bankTha"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import thailandMixin from '@/mixins/page/deposit/thailand';
import { apiXpay_payment } from '@/resource';
import { autoForm } from '@/util/getAutoForm';

export default {
  name: 'thailandPayment_xpay',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin, thailandMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && this.rateChange > this.maxLimit) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: 'USD' })));
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.getMaxUSCByRate(this.maxLimit, this.rate)) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      zotaRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      bankHTML: '',
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst12'),
        this.$t('deposit.reminder.inst13')
      ],
      showNote: false
    };
  },
  methods: {
    submitXpayForm() {
      this.$refs['zotaForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                this.bankHTML = autoForm(result.data.msg);
                if (this.bankHTML.length > 0) {
                  this.$nextTick(function() {
                    if (document.getElementById('AutoForm')) {
                      document.getElementById('AutoForm').submit();
                    } else {
                      this.loading = false;
                      this.errorMessage(this.$t('deposit.default.failed'));
                    }
                  });
                } else {
                  this.loading = false;
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                if (result.data.code !== 562) this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiXpay_payment(
        {
          mt4Account: this.zotaForm.accountNumber,
          operateAmount: this.zotaForm.amount,
          applicationNotes: this.zotaForm.notes,
          depositAmount: this.getRate(this.rateChange),
          rate: this.rate
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
